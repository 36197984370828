<template>
  <div>
    <!-- <address-list :addresses="entity.addresses" :is-tiled="true" :v="$v.entity.addresses"></address-list> -->
    <!-- <address-list-mapzen :addresses="entity.addresses" :is-tiled="true" :v="$v.entity.addresses"></address-list-mapzen> -->
    <!-- <mapzen-auto-complete :addresses="entity.addresses" :v="$v.entity.addresses"></mapzen-auto-complete> -->
    <address-view
      :addresses="entity.addresses"
      :is-tiled="true"
      :v="$v.entity.addresses" />
  </div>
</template>

<script>
import { AddressView } from '@/components/Address'

export default {
  name: 'InsurerAddress',
  inject: ['$vv'],
  components: {
    AddressView
  },
  props: {
    entity: null
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv
    }
  },
  mounted: function() {
    this.$v.entity.addresses.$touch()
  },
  beforeRouteLeave: function(to, from, next) {
    next()
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Insurer'
    // if (!this.$v.entity.addresses.$error || this.entity.isNew) {
    //   next()
    // } else {
    //   this.$router.replace(from.path)
    //   this.$notification.error(title, message)
    // }
  }
}
</script>

<style lang="scss">
</style>
