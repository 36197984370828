<template>
  <div :class="{'tile is-ancestor is-parent': true}">
    <phone-list
      :phones="phones"
      :v="$v.entity.phones"
      :is-tiled="isTiled" />
    <email-list
      :emails="emails"
      :v="$v.entity.emails"
      :is-tiled="isTiled" />
  </div>
</template>

<script>
import PhoneList from '@/components/Phone'
import EmailList from '@/components/Email'

export default {
  name: 'InsurerPhoneEmail',
  inject: ['$vv'],
  components: {
    PhoneList,
    EmailList
  },
  props: {
    entity: null,
    isTiled: {
      type: Boolean,
      default: true
    },
    v: null // Optional Validator Object
  },
  computed: {
    phones: function() {
      return this.entity.phones
    },
    emails: function() {
      return this.entity.emails
    },
    // $entity: function () {
    //   if (this.$v.entity) {
    //     // From route view
    //     return this.$v.entity
    //   } else {
    //     // From component tab
    //     return this.$v
    //   }
    // },
    $v() {
      return this.$vv
    }
  },
  mounted() {
    this.$v.entity.emails.$touch()
    this.$v.entity.phones.$touch()
    // this.$entity.$touch()
  },
  beforeRouteLeave: function(to, from, next) {
    next()
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Insurer'
    // if (!(this.$v.entity.emails.$error) && !(this.$v.entity.phones.$error) || this.entity.isNew) {
    //   next()
    // } else {
    //   this.$router.replace(from.path)
    //   this.$notification.error(title, message)
    // }
  }
}
</script>
